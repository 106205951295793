import {
  HomePageBannerImageDesktop,
  HomePageBannerImageMobile
} from '@components/home/HomePageBanners';
import {GoogleMerchantReviewBadgeRating} from '@components/google-badge/google-badge-rating';
import {useEffect, useState} from 'react';
import {
  HomePageFeaturedCollectionBannersDesktop,
  HomePageFeaturedCollectionBannersMobile
} from '@components/home/HomePageFeaturedCollections';

export const HomePage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {/* Desktop View */}
      <div className={'md:block hidden'}>
        <div id="homepage-desktop" className="mb-2">
          {/*<EmergencyBannerDesktop />*/}
          <HomePageBannerImageDesktop/>
          <HomePageFeaturedCollectionBannersDesktop/>
        </div>
      </div>

      {/* Mobile View */}
      <div className={'block md:hidden'}>
        <div id="homepage-mobile" className="relative" suppressHydrationWarning={true}>
          {/*<EmergencyBannerMobile />*/}
          <HomePageBannerImageMobile/>
          <HomePageFeaturedCollectionBannersMobile/>
        </div>
      </div>

      {isLoaded && <GoogleMerchantReviewBadgeRating/>}
    </>
  );
};

export const EmergencyBannerDesktop = () => (
  <div
    className="text-center py-2 font-bold"
    style={{
      backgroundColor: 'rgb(44,103,74)',
      color: 'rgb(231,231,231)'
    }}>
    🌀 Shipments from our FL Warehouse may be delayed until Friday, 9/27 due to Hurricane Helene 🌀
  </div>
);

export const EmergencyBannerMobile = () => (
  <div
    className="text-center text-sm py-2 font-bold"
    style={{
      backgroundColor: 'rgb(44,103,74)',
      color: 'rgb(231,231,231)'
    }}>
    🌀 Shipments from our FL Warehouse may be delayed until Friday, 9/27 due to Hurricane Helene 🌀
  </div>
);
